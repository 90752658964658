import React from 'react';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import IntlMessages from 'util/IntlMessages';

import CircularProgress from '@material-ui/core/CircularProgress';

import cookie from "react-cookies";

import {
  hideMessage,
  showAuthLoader,
  pmUserSignIn
} from '../actions';

class SignIn extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      employeePassword: '',
      employeeLogin: '',
      password: '',
      token : cookie.select()['pm-token'] ? cookie.select()['pm-token'] : null
    };

    this.handleUserData = this.handleUserData.bind(this);
  }

  componentDidMount() {
    if (this.state.token && this.props.authUser) {
      this.redirectFunction();
    }
  }

  componentDidUpdate(prevState,prevProps,snapShot) {
    const {showMessage,hideMessage} = this.props;
     if (showMessage) {
      setTimeout(() => {
        hideMessage();
      }, 100);
    }
    this.redirectFunction();
  }

  redirectFunction= () => {
    const {authUser,globalSettings,history} = this.props;
    if (authUser) {
      if(globalSettings && globalSettings.label && ['VAMOSETH', 'HABESHAETH', 'BWANABET', 'JEGINA'].includes(globalSettings.label.project)){
        history.replace('/app/dashboard/dashboard');
      }else {
        history.replace('/app/dashboard/sports');
      }
    }
  };

  handleDataChange(key,event) {
    this.setState({
      [key]: event.target.value,
    });

    if (event && event.key === 'Enter') {
      this.handleUserData();
    }
  }

  handleUserData() {
    // this.props.showAuthLoader();

    let {username, password,employeePassword,employeeLogin} = this.state;

    if (!username || !password) return false;

    this.props.pmUserSignIn({username, password,employeePassword,employeeLogin});
  }


  render() {
    const {showMessage, loader, alertMessage, authLoader, countrySettings,globalSettings} = this.props;
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">

          <div className={`app-logo-content ${globalSettings?.label?.project ==='MAVERIX' &&  `bg_mavericks`} d-flex align-items-center justify-content-center`}>
            {countrySettings && countrySettings.logo ? <img style={{width:"100%"}} src={countrySettings.logo.signIn} alt={countrySettings.logo.alt} title={countrySettings.logo.title}/> : <div />}
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1><IntlMessages id="SIGN IN"/></h1>
            </div>

            <div className="app-login-form">
              <form>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="Username"/>}
                    fullWidth
                    onChange={(e)=> this.handleDataChange('username',e)}
                    onKeyPress={(e)=> this.handleDataChange('username',e)}
                    defaultValue=''
                    tabIndex={1}
                    margin="normal"
                    className="mt-1 my-sm-3"
                    autoFocus={true}
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="Password"/>}
                    fullWidth
                    onChange={(e)=> this.handleDataChange('password',e)}
                    onKeyPress={(e)=> this.handleDataChange('password',e)}
                    defaultValue=''
                    tabIndex={2}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  {(globalSettings.label && globalSettings.label.CBTwoFactorAuth)
                      ?
                        <>
                          <h1 style={{marginTop:'40px'}}><IntlMessages id="EMPLOYEE SIGN IN"/></h1>
                          <TextField
                              label={<IntlMessages id="Employee Username"/>}
                              fullWidth
                              onChange={(e)=> this.handleDataChange('employeeLogin',e)}
                              onKeyPress={(e)=> this.handleDataChange('employeeLogin',e)}
                              defaultValue=''
                              tabIndex={1}
                              margin="normal"
                              className="mt-1 my-sm-3"
                          />
                          <TextField
                              type="password"
                              label={<IntlMessages id="Employee Password"/>}
                              fullWidth
                              onChange={(e)=> this.handleDataChange('employeePassword',e)}
                              onKeyPress={(e)=> this.handleDataChange('employeePassword',e)}
                              defaultValue=''
                              tabIndex={2}
                              margin="normal"
                              className="mt-1 my-sm-3"
                          />
                        </>
                      :
                        <></>
                  }

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button disabled={authLoader} tabIndex={3} onClick={this.handleUserData} variant="contained" color="primary"><IntlMessages id="Log in"/></Button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>

        </div>
        {loader && <div className="loader-view"><CircularProgress/></div>}
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer/>
      </div>
    )
  }
}

const mapStateToProps = ({authReducer, settingsReducer}) => {
  const {loader, alertMessage, showMessage, authUser, authLoader} = authReducer;
  const {countrySettings, globalLoader,globalSettings} = settingsReducer;
  return {loader, alertMessage, showMessage, authUser, authLoader, countrySettings, globalLoader,globalSettings}
};

export default connect(mapStateToProps, {
  pmUserSignIn,
  hideMessage,
  showAuthLoader
})(SignIn);
