import React from 'react';
import {connect} from 'react-redux'
import {pmUserSignOut} from '../../actions/Auth';
import IntlMessages from 'util/IntlMessages';
import {NavLink} from "react-router-dom";

class UserInfo extends React.Component {

  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = event => {
    this.setState({open: true, anchorEl: event.currentTarget});
  };

  handleRequestClose = () => {
    this.setState({open: false});
    this.props.pmUserSignOut()
  };

  render() {
    const {globalSettings} = this.props;
      return (
          <div>
            <div className="user-profile d-flex flex-row align-items-center">
              <div className="user-detail">
                <h4 className="user-name" onClick={this.handleClick}>
                  {this.props.authUser ? this.props.authUser.username : ''}
                </h4>
              </div>
              <span className='user-profile__sing-out' onClick={() => {this.handleRequestClose()}}><IntlMessages id="SIGN OUT"/><i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/></span>
            </div>
              <h3 style={{padding:"20px 20px 10px 20px",margin:0}}>Dashboard</h3>
              <ul className="navbar-nav navbar-nav-mega">
                {(globalSettings.label && ['VAMOSETH', 'HABESHAETH', 'JEGINA'].includes(globalSettings.label.project))
                    ?
                      <>
                        <li className="nav-item" style={navBarStyle}>
                          <NavLink className="prepend-icon" to="/app/dashboard/dashboard">
                            <span className="nav-text"><IntlMessages id="Dashboard"/></span>
                          </NavLink>
                        </li>
                        {!['JEGINA'].includes(globalSettings.label.project) &&
                          <li className="nav-item" style={navBarStyle}>
                            <NavLink className="prepend-icon" to="/app/dashboard/sports">
                              <span className="nav-text"><IntlMessages id="Sports"/></span>
                            </NavLink>
                          </li>
                        }
                      </>
                    :
                    <>
                      {/* <li className="nav-item" style={navBarStyle}>
                        <NavLink className="prepend-icon" to="/app/dashboard/live">
                          <span className="nav-text"><IntlMessages id="Live"/></span>
                        </NavLink>
                      </li> */}
                      <li className="nav-item" style={navBarStyle}>
                        <NavLink className="prepend-icon" to="/app/dashboard/inPlay">
                            <span className="nav-text">
                                <IntlMessages id="In Play" />
                            </span>
                        </NavLink>
                      </li>
                      <li className="nav-item" style={navBarStyle}>
                        <NavLink className="prepend-icon" to="/app/dashboard/sports">
                          <span className="nav-text"><IntlMessages id="Sports"/></span>
                        </NavLink>
                      </li>
                      <li className="nav-item" style={navBarStyle}>
                        <NavLink className="prepend-icon" to="/app/dashboard/keno">
                          <span className="nav-text"><IntlMessages id="Keno"/></span>
                        </NavLink>
                      </li>
                      {/* <li className="nav-item" style={navBarStyle}>
                        <NavLink className="prepend-icon" to="/app/dashboard/dogs">
                          <span className="nav-text"><IntlMessages id="Dogs"/></span>
                        </NavLink>
                      </li> */}
                      <li className="nav-item" style={navBarStyle}>
                        <NavLink to="/app/dashboard/wof">
                          <span className="nav-text"><IntlMessages id="WOF"/></span>
                        </NavLink>
                      </li>
                      <li className="nav-item" style={navBarStyle}>
                        <NavLink to="/app/dashboard/kaboom">
                          <span className="nav-text"><IntlMessages id="Kaboom"/></span>
                        </NavLink>
                      </li>
                      {/*<li className="nav-item" style={navBarStyle}>*/}
                      {/*  <NavLink to="/app/dashboard/pick-five">*/}
                      {/*    <span className="nav-text"><IntlMessages id="Pick Five"/></span>*/}
                      {/*  </NavLink>*/}
                      {/*</li>*/}
                      {/* <li className="nav-item" style={navBarStyle}>
                        <NavLink to="/app/dashboard/horses">
                          <span className="nav-text"><IntlMessages id="Horses"/></span>
                        </NavLink>
                      </li> */}
                      {/*<li className="nav-item" style={navBarStyle}>*/}
                      {/*  <NavLink to="/app/dashboard/virtual-football">*/}
                      {/*    <span className="nav-text"><IntlMessages id="Virtual Football"/></span>*/}
                      {/*  </NavLink>*/}
                      {/*</li>*/}
                    </>
                }
                {!['JEGINA'].includes(globalSettings.label.project) &&
                  <>
                    <li className="nav-item" style={navBarStyle}>
                      <NavLink className="prepend-icon" to="/app/dashboard/finance">
                        <span className="nav-text"><IntlMessages id="Finance"/></span>
                      </NavLink>
                    </li>
                    <li className="nav-item" style={navBarStyle}>
                      <NavLink className="prepend-icon" to="/app/dashboard/pay-out">
                        <span className="nav-text"><IntlMessages id="Payouts"/></span>
                      </NavLink>
                    </li>
                  </>
                }
                {globalSettings?.label?.project !== 'MAVERIX' && 
                  <li className="nav-item" style={navBarStyle}>
                    <NavLink className="prepend-icon" to="/app/dashboard/website">
                      <span className="nav-text"><IntlMessages id="Website"/></span>
                    </NavLink>
                  </li>
                }
                {!['JEGINA'].includes(globalSettings.label.project) &&
                  <>
                    <li className="nav-item" style={navBarStyle}>
                      <NavLink className="prepend-icon" to="/app/dashboard/bets">
                        <span className="nav-text"><IntlMessages id="Bets"/></span>
                      </NavLink>
                    </li>
                    <li className="nav-item" style={navBarStyle}>
                      <NavLink className="prepend-icon" to="/app/dashboard/results">
                        <span className="nav-text"><IntlMessages id="Results"/></span>
                      </NavLink>
                    </li>
                    <li className="nav-item" style={navBarStyle}>
                      <NavLink className="prepend-icon" to="/app/dashboard/downloads">
                        <span className="nav-text"><IntlMessages id="Downloads"/></span>
                      </NavLink>
                    </li>
                  </>
                }
                {(globalSettings.label && globalSettings.label.project === 'PMBETTZ') &&
                  <li className="nav-item">
                    <NavLink className="prepend-icon" to="/app/dashboard/jackpot">
                      <span className="nav-text"><IntlMessages id="Jackpot"/></span>
                    </NavLink>
                  </li>
                }
              </ul>
            </div>
    );
  }
}

const navBarStyle = {padding: "0 30px"};

const mapStateToProps = ({settingsReducer,authReducer}) => {
  const {locale,globalSettings} = settingsReducer;
  const {authUser} = authReducer;

  return {locale,authUser,globalSettings}
};
export default connect(mapStateToProps, {pmUserSignOut})(UserInfo);


